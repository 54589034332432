@import "../RootStyle";

#event-detail-page{
  ion-card-header {
    padding-top: 0;
  }
  ion-card-content {
    padding-bottom: 0;
  }

  .viae-icon {
    margin-right: 25px;
  }
}
