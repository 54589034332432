#calendar-page {

  ion-item-sliding.track-toneel ion-label {
    border-left: 5px solid #e81b0b;
    padding-left: 10px;
  }
  ion-item-sliding.track-harmonie ion-label {
    border-left: 5px solid #007b7b;
    padding-left: 10px;
  }

  .calendar-item-label {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
