@import "../RootStyle";

#organisation-detail-page{
  ion-card-header {
    padding-top: 0;
  }
  ion-card-content {
    padding-bottom: 0;
  }
}
