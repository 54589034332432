@import "../RootStyle";

#organisation-group-edit-members-page{
  ion-item.no-background {
    --background: none;
  }

  ion-item.no-border {
    --inner-border-width: 0 0 0 0;
  }
}
