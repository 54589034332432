@import "Colors";

@media (min-width: 480px) {
  .content-container {
    padding: 15px 20px;
  }

  .icon {
    margin-right: 25px;
  }
}
@media (max-width: 480px) {
  .content-container {
    padding: 0;
  }

  .icon {
    margin-right: 0;
  }
}

.content-container {
  margin-bottom: 75px;

  .list-item-with-icon {
    .text {
      line-height: 17px;
      vertical-align: middle;
    }

    .icon {
      height: 17px;
      vertical-align: middle;
    }
  }

  .hoverable {
    cursor: pointer;
    --background: none;

    &:hover {
      background: $hoverColor;
    }
  }
}
