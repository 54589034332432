.input-item {

  &.in-error {
    --border-color: #eb445a;
    --highlight-color-invalid: #eb445a;
    --highlight-color-valid: #eb445a;

    .error-message, .label {
      color: #eb445a;
    }
  }
}
